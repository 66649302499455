import EmailObfuscate from 'email-obfuscate';

let el = document.getElementById('email');

EmailObfuscate(el, {
    // Email construct: name@domain.tld
    name: 'contact',
    domain: 'abundancecreation',
    tld: 'org',
    // Alternate Text
    altText: 'Email'
});